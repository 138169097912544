import React, { useEffect, useState } from "react";

export default function QualificationShortInfo({ qualification }) {
  const [courseType, setCourseType] = useState(null);

  useEffect(() => {
    if (qualification) {
      switch (qualification.type) {
        case "full":
          setCourseType("Full Time");
          break;
        case "part":
          setCourseType("Part Time");
          break;
        case "cores":
          setCourseType("Correspondence");
          break;
        case "fulltimeonline":
          setCourseType("Full Time (Online)");
          break;    
        case "fulltimecontact":
          setCourseType("Full Time (Contact)");
          break;
        case "parttimeonline":
          setCourseType("Part-Time (Online)");
          break;
        default:
          setCourseType("Full Time");
          break;
      }
    }
  }, [qualification]);
  
  return (
    <>
      <h3>{qualification.course}</h3>
      <p>
        {qualification.type}, Intake {qualification.number}, {qualification.year}
      </p>
    </>
  );
}
