import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import Swal from "sweetalert2";
import { AddNotesService } from "../../services/AssembliesService";
import SkeletonTicketList from "../../loaders/SkeletonTicketList";

const StyledDiv = styled.div`
  .place-right {
    float: right;
  }
  .icon {
    width: 25%;
    position: absolute;
    top: 30%;
    left: 35%;
  }
  .wrap-text {
    margin: 15px 0 0 35px;
    .highlighttext {
      font-weight: 700;
    }
    .label {
      font-size: 12px;
      margin: 5px 0 0 15px;
    }
  }
`;

export default function CreateNotesLesson(props) {
  const [loading, setLoading] = useState(false);
  const { lessonItemId, subjectId } = props.props;
  const history = useHistory();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      intake_id: "",
      notes_content: "",
      assemblies_items_id: "",
    },
    validationSchema: Yup.object({
      notes_content: Yup.string().required("Notes is required"),
    }),
    onSubmit: (values) => {
      setLoading(true);
      const formInputs = new FormData();
      formInputs.append("intake_id", subjectId);
      formInputs.append("notes_content", values.notes_content);
      formInputs.append("assemblies_items_id", lessonItemId);
      AddNotesService(formInputs)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Submitted",
            timer: 3000,
            onOpen: function () {
              Swal.showLoading();
            },
          })
          .then(function (result) {
            setLoading(false);
            if (result.dismiss === "timer") {
              // history.push("/home");
              props.closeAddNotes(false)
            }
          });
        })
        .catch((error) => {
          setLoading(false);
        });
    },
  });

  return (
    <>
      {loading && <SkeletonTicketList />}
      {!loading && <div className="Tickets-main-wrap notes-main-wrap">
        <div className="card-body">
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-xl-12 form-group">
                <label>
                  <b>Add New Notes</b>
                </label>
                <input
                  type="text"
                  name="notes_content"
                  className={
                    "form-control" +
                    (formik.errors.notes_content &&
                      formik.touched.notes_content
                      ? " is-invalid"
                      : "")
                  }
                  placeholder="Add any notes you would like to take."
                  value={formik.values.notes_content}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.notes_content &&
                  formik.errors.notes_content && (
                    <div className="invalid-feedback d-block">
                      {formik.errors.notes_content}
                    </div>
                  )}
              </div>
              <div className="form-group atttach-file m-0 col-12 d-flex ">
                <div className="m-2">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={loading}
                  >
                    {loading && <i className="fas fa-cog fa-spin"></i>}{" "}
                    <i className="fal fa-save"></i>
                    Save
                  </button>
                </div>
                <div className="m-2">
                  <a
                    className="btn btn-danger"
                    onClick={() => props.closeAddNotes(false)}
                  >
                    <i className="fal fa-times"></i>
                    Cancel
                  </a>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    } 
    </>
  );
}
