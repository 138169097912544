import React, { useState, useEffect, useCallback, memo } from "react";
import {
  getAssembliesItem,
  fileTrackingService,
  getAssembliesItemAdmin,
} from "../../services/AssembliesService";

import AssembliesItems from "./AssembliesItems";
import SekletonLearningMaterial from "../../loaders/SekletonLearningMaterial";
import { useDispatch, useSelector } from "react-redux";
import { UpdateLessonType, clearLessonType, updateCurrentActivity, updateCurrentAssembly, updateCurrentCategory } from "../../store/actions";

function SubjectAssemblies(props) {
  const {
    setQuizId,
    setshow,
    setrId,
    moduleId,
    getSubContentMethod,
    subassembliesItems,
    assembliesItems,
    setassembliesItems,
    setSubassembliesItems,
  } = props;

  const [loading, setLoading] = useState(false);
  const [currentVideoId, setCurrentVideoId] = useState(0);
  const [buttonCheckedStatus, setButtonCheckedStatus] = useState(0);
  const [documentId, setDocumentId] = useState([]);
  const [lessonList, setLessonList] = useState([]);
  // const [assembliesItems, setassembliesItems] = useState([]);
  const currentAssembly = useSelector(state => state.learningMaterial.currentAssembly)
  const dispatch = useDispatch();
  
  const activityArr = useSelector(state => state.learningMaterial.activity_arr);
  const currentActivity = useSelector(state => state.learningMaterial.current_activity);
  const lesson_type = useSelector(state => state.learningMaterial.active_lesson_type);

  useEffect(() => {
    if (moduleId) {
      getCategoryLearningMaterial(moduleId);
    }
  }, [moduleId]);

  const getCategoryLearningMaterial = useCallback((item) => {
    setLoading(true)
    getAssembliesItem(item)
      .then((res) => {
        setassembliesItems(res.data);
        setLessonList(res.data);
        setLoading(false)
      })
      .catch((error) => {
        console.log(error);
        setLoading(false)
      }).finally(
        setLoading(false)
      );
  }, []);

  const handleFileTracking = useCallback((itemId) => {
    fileTrackingService(itemId).then((res) => {
      setButtonCheckedStatus(true);
      setDocumentId(prevDocumentId => [...prevDocumentId, itemId]);
    });
  }, [props.subjectId]);

  function handleNextActivity() {

    let previousActivityIndex = activityArr.findIndex((activity) => {
      return activity?.item_id === currentActivity;
    });

    let nextActivity = activityArr[previousActivityIndex + 1];

    if (nextActivity) {
      dispatch(updateCurrentActivity(nextActivity?.item_id));
      dispatch(updateCurrentAssembly(nextActivity.assembly_id));
      dispatch(UpdateLessonType(nextActivity.item_type));
    }
    
  }
  const fetchAssembliesItem = async (item) => {
    try {
      const fetchFunction = props.isAdmin === 1 ? getAssembliesItemAdmin : getAssembliesItem;
      const res = await fetchFunction(item);
      if(res.status == 200){
        setassembliesItems(res.data);
        let currentActivityArr= activityArr.filter((activity)=>activity?.item_id==item)
        let currentAssemblyId= currentActivityArr[0].assembly_id
        dispatch(updateCurrentActivity(item));
        dispatch(updateCurrentAssembly(currentAssemblyId))
        dispatch(clearLessonType());
        setLessonList(res.data)
      }    
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.error(error);
    }
  };

  const fetchSubAssembliesItem = async (item) => {
    try {
  
      const fetchFunction = props.isAdmin === 1 ? getAssembliesItemAdmin : getAssembliesItem;
      const res = await fetchFunction(item);
      if(res.status == 200){
        props.setSubassembliesItems(res.data);
        dispatch(updateCurrentActivity(item));
        dispatch(clearLessonType());
        setLessonList(res.data)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.error(error);
    }
  };

  useEffect(() => {
    if (currentActivity && lesson_type === 1) {
      fetchAssembliesItem(currentActivity)
      setLessonList(assembliesItems);
    } else if (currentActivity && lesson_type === 3) {
      fetchSubAssembliesItem(currentActivity)
      setLessonList(subassembliesItems);
    } else {
      const AssembliestItemsLessons = assembliesItems.filter((lesson) => lesson?.item_id === currentActivity);
      const SubAssembliestItemsLessons = subassembliesItems.filter((lesson) => lesson?.item_id === currentActivity);

      if (AssembliestItemsLessons.length) {
        setLessonList(AssembliestItemsLessons);
      } else if (SubAssembliestItemsLessons.length) {
        setLessonList(SubAssembliestItemsLessons);
      } else {
        setLessonList(assembliesItems);
      }
    }
  }, [currentActivity, lesson_type, assembliesItems, subassembliesItems]);

  return (
    <> 
      {loading && <SekletonLearningMaterial />}
      {!loading && lessonList.length > 0 ? lessonList.map((lesson, lessonindex) => (
        <div key={lessonindex} className="learning-material-listing learning-material-content">
          <div className="learning-material-body">
            <ul className="sub-ul">
              <AssembliesItems
                subassembliesItems={props.subassembliesItems}
                subjectId={props.subjectId}
                assembliesItems={assembliesItems}
                key={lessonindex}
                isAdmin={props.isAdmin}
                parentSubId={props.subjectId}
                assemblyItem={assembliesItems}
                setQuizId={setQuizId}
                setrId={setrId}
                lesson={lesson}
                setshow={setshow}
                parentSetCurrentVideoId={setCurrentVideoId}
                lessonindex={lessonindex}
                parentFileTracking={handleFileTracking}
                currentVideoId={currentVideoId}
                getSubContentMethod={getSubContentMethod}
                buttonCheckedStatus={buttonCheckedStatus}
                documentId={documentId}
              />
            </ul>
          </div>
        </div>
      )) : !loading && lessonList.length < 1 && <>
        <div className="learning-material-listing" id="accordion" role="tablist" aria-multiselectable="true">
          <div className="subject-header">
            <div className="subject-sub-header">
              <h3>No Content Available.</h3>
            </div>
          </div>
        </div>
      </>
      }
      

      {!loading && currentActivity !== activityArr[activityArr.length - 1]?.item_id && (
        <a style={{ float: "right" }} className="next-activity" onClick={handleNextActivity}>
          Go to next activity <span><i className="fal fa-greater-than"></i></span>
        </a>
      )}
    </>
  );
}

export default memo(SubjectAssemblies);
