import React, { useState, useEffect, useRef, lazy, Suspense, Children } from "react";
import SideBar from "../components/common/SideBar";
import {
  GetMyPastQualificationService,
  GetMyQualificationService,
  GetShortCourseQualification,
} from "../services/QualificationService";
import SkeletonQaulification from "../loaders/SkeletonQualification";
import QualificationBox from "../components/course/QualificationBox";
import { getCurrentTimeHomePage } from "../components/common/Helper";
import { UpdateStudentTimeZone } from "../services/CalendarService";
import PastQualificationBox from "../components/course/PastQualificationBox";
import Header from "../components/common/Header";

const MyCourses = () => {
  const [isPast, setIsPast] = useState(0);
  const [qualifications, setQualifications] = useState([]);
  const [pastQualification, setPastQualification] = useState([]);
  const [shortCourse, setShortCourse] = useState([]);
  const [pastShortCourse, setPastShortCourse]=useState([])
  const [loading, setLoading] = useState(true);
  const [showQual, setShowQual] = useState(true);
  const [totalQual, setTotalQual] = useState(0);
  const [totalSCourses, setTotalSCourses] = useState(0);
  const [showTabs, setShowTabs] = useState(false);


  useEffect(() => {
      
    if (showQual) {
      setLoading(true)
      const fetchQualifications = async () => {
        try {
          const resCurrent = await GetMyQualificationService({ isPast: 0 });
          setShowTabs(true)
          setQualifications(resCurrent.data?.data || []);
          setLoading(false);
        } catch (error) {
          console.error(error);
          setLoading(false);
        }
      };
      const fetchQualificationPast = async () => {
        try {
          const resPast = await GetMyPastQualificationService({ isPast: 1 });          setShowTabs(true)
          setPastQualification(resPast.data?.data || []);  
        } catch (error) {
          console.error(error);
        }
      };
      fetchQualifications();
      fetchQualificationPast();
    }   

    if (!showQual) {
      setLoading(true)
      
      GetShortCourseQualification()
      .then((res) => {
        const response = res?.data || [];
        const newShortCourses = [];
        const newPastShortCourses = [];

        response.forEach((item) => {
          if (item.isPast) {
            newPastShortCourses.push(item);
          } else {
            newShortCourses.push(item);
          }
        });

        setShortCourse(newShortCourses);
        setPastShortCourse(newPastShortCourses);
        if (res.data.length > 0) {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching short courses: ", error);
        setLoading(false);
      });
    }
    
    UpdateStudentTimeZone({
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }).then((res) => {
      if (res.status === 200) {
        // handle success
      }
    }).catch((err) => {
      console.log(err);
    });
  }, [showQual]);


  return (
    <>
        <Header />
      <div className="content-wrapper d-flex flex-wrap">
        <SideBar />
        <div className="sidebar-right">
          <div className="mycourses-list-wrap mycourses-edit tab-sec">
            <div className="my-tickets-heading-blk flex-direction mb-4">
              <h4 className="text-left-align landing-heading heading_color_dynamic my-courses-title">
                My <span> Courses</span>
              </h4>
              <div className="text-md-right">
                <p className=""></p>
              </div>
              <div className="lecture-tabs-wrap lecture-time-box">
                <ul className="nav nav-pills" id="pills-tab" role="tablist">
                  {showTabs && (
                    <>
                      <li className={`nav-item ${showQual ? 'active' : ''}`} title="Qualifications">
                        <a
                          id="pills-Posts-tab"
                          data-toggle="pill"
                          href="#pills-Posts"
                          role="tab"
                          aria-controls="pills-Posts"
                          aria-selected="true"
                          className="nav-link active"
                          onClick={() => setShowQual(true)}
                        >
                          <i className="far fa-graduation-cap" aria-hidden="true"></i>
                          Qualifications
                          {totalQual !== 0 && (
                            <div className="nav-fa-comments my-crs-head" title="You have unread messages">
                              <small className="notify-count">{totalQual < 10 ? totalQual : "9+"}</small>
                              <i className="fal fa-comments"></i>
                            </div>
                          )}
                        </a>
                      </li>

                      <li className={`nav-item ${!showQual ? 'active' : ''}`} title="Short Courses">
                        <a
                          className="nav-link"
                          id="pills-Overview-tab"
                          data-toggle="pill"
                          href="#pills-Overview"
                          role="tab"
                          aria-controls="pillsOverview"
                          aria-selected="false"
                          onClick={()=>setShowQual(false)}
                        >
                          <i className="fal fa-award"></i>
                          Short Courses
                          {totalSCourses !== 0 && (
                            <div className="nav-fa-comments my-crs-head" title="You have unread messages">
                              <small className="notify-count">{totalSCourses}</small>
                              <i className="fal fa-comments"></i>
                            </div>
                          )}
                        </a>
                      </li>
                    </>
                  )}
                </ul>
                <p className="date-time"> {getCurrentTimeHomePage(Date().toLocaleString())}</p>
              </div>
            </div>

            {loading && <SkeletonQaulification />}
  
            {!loading && qualifications?.length > 0 &&  (
              <QualificationBox
                showQualification={showQual}
                qualification={showQual ? qualifications : shortCourse}
                showProgram={true}
                state={{ isPast, qualifications, pastQualification, shortCourse, loading, showQual, totalQual, totalSCourses, showTabs }}
              />
            )}
            {!loading && pastQualification?.length > 0 ?
              <>
                <button
                  data-toggle="collapse"
                  data-target="#show-hide-past-qualification"
                  aria-expanded="false"
                  className={`hide collapsed ${showQual ? "hide-completed-program" : "hide-completed-course"}`}
                ></button> 

                <div className="collapse" id="show-hide-past-qualification">
                  <PastQualificationBox
                    state={{ isPast, qualifications, pastQualification, pastShortCourse, loading, showQual, totalQual, totalSCourses, showTabs }}
                  />
                </div>
              </>
              : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default MyCourses;
