const initialState = {
    active_lesson_type:"",
    assessmentActive:false,
    current_activity:"",
    current_category:"",
    currentAssembly:"",
    activity_arr:[]
};

const updateLearningMaterialData = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_LESSON_TYPE' :
        return {...state,active_lesson_type:action.payload}
        case 'EMPTY_LESSON_TYPE':
        return {...state,active_lesson_type:""}
        case 'UPDATE_ASSESSMENT_ACTIVE':
        return { ...state, assessmentActive: action.payload }
        case 'EMPTY_ASSESSMENT_ACTIVE':
        return { ...state, assessmentActive: false }
        case 'UPDATE_ACTIVITY_ARR':
        return { ...state, activity_arr: action.payload }
        case 'EMPTY_ACTIVITY_ARR':
        return { ...state, activity_arr: [] }
        case 'UPDATE_CURRENT_ACTIVITY':
        return { ...state, current_activity: action.payload }
        case 'EMPTY_CURRENT_ACTIVITY':
        return { ...state, current_activity: "" }
        case 'UPDATE_CURRENT_CATEGORY':
        return { ...state, current_category: action.payload }
        case 'EMPTY_CURRENT_CATEGORY':
        return { ...state, current_category: "" }
        case 'UPDATE_CURRENT_ASSEMBLY':
        return { ...state, currentAssembly: action.payload }
        default:
        return state;
    }
}

export default updateLearningMaterialData;