import React, { useState, useEffect } from "react";
import ShortNameBox from "./ShortNameBox";
import {subjectDuration,getSubjectStatus} from "../../utils/commonFunction.js";
import moment from "moment";
import Str from "../common/Str";
import MentoredSubject from "./MentoredSubject";
import { ProgramIcons } from "./CommonCourseComponents";
import { loadGraphModel } from "@tensorflow/tfjs";
const stc = require('string-to-color');

const SubjectHeadBox = (props) => {
  const {total_documents,total_assessment,totalLearningMaterialPercentage,total_videos,total_quizs,total_links}=props?.subjectContent?.progress
  const row=props.content;
  const [status, setStatus] = useState(null);
  const [statusIcon, setStatusIcon] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {    
    let icon = getSubjectStatus(row.subjectStatus);
    setStatus(row.subjectStatus);
    setStatusIcon(icon);
  }, [props.content]);


  return (
    <>
      <div className="lecture-detail-top d-flex justify-content-between  shift-move">
        <div className="lecture-detail-top-left">
          <div className="lecture-bx-info  d-flex">
            <ShortNameBox qualification={{ course: row.name }} paramColor={stc(row.name)}></ShortNameBox>
            {/* <div className="icon honours-icon"><ProgramIcons icon={"honours"}/></div> */}
            <div className="lecture-bx-info-rgt">
              <h3>{row.name}</h3>
              <div className="course-statistics-count">
                {props.isAdmin!=1 ? 
                  (() => {
                    if(row.type === "selfplaced" && row.subject_type === "shortcourse"){
                      switch(row.student_intake_status){
                        case 0: 
                        return <p><i className="fal fa-clock"></i>{row?.subject_duration + " months"}</p>;
                        case 1:
                        case 4:
                        return <p><i className="fal fa-clock"></i>{moment(row.end, "Do MMM YY").format(Str.TABLE_DATE_FORMAT)} to {moment(row.start, "Do MMM YY").format(Str.TABLE_DATE_FORMAT)} {subjectDuration(row.start, row.end)}</p>
                        case 2: 
                        case 3:
                        return <p><i className="fal fa-clock"></i>{moment(row.end, "Do MMM YY").format(Str.TABLE_DATE_FORMAT)} to {moment(row.start, "Do MMM YY").format(Str.TABLE_DATE_FORMAT)} {subjectDuration(row.start, row.end)}</p>
                        default:
                        return <p><i className="fal fa-clock"></i>{moment(row.end, "Do MMM YY").format(Str.TABLE_DATE_FORMAT)} to {moment(row.start, "Do MMM YY").format(Str.TABLE_DATE_FORMAT)} {subjectDuration(row.start, row.end)}</p>
                        }
                    }else if (row.subject_type=="mentored"){
                      switch (row.student_intake_status) {
                        case 0:   
                        return <p><i className="fal fa-clock"></i>{moment(row.end, "Do MMM YY").format(Str.TABLE_DATE_FORMAT)} to {moment(row.start, "Do MMM YY").format(Str.TABLE_DATE_FORMAT)} {subjectDuration(row.start, row.end)}</p>
                        case 1: 
                        case 4: 
                        return <p><i className="fal fa-clock"></i>{moment(row.end, "Do MMM YY").format(Str.TABLE_DATE_FORMAT)} to {moment(row.start, "Do MMM YY").format(Str.TABLE_DATE_FORMAT)} {subjectDuration(row.start, row.end)}</p>
                        case 2: 
                        case 3:   
                        return <p><i className="fal fa-clock"></i>{moment(row.end, "Do MMM YY").format(Str.TABLE_DATE_FORMAT)} to {moment(row.start, "Do MMM YY").format(Str.TABLE_DATE_FORMAT)} {subjectDuration(row.start, row.end)}</p>
                        default: 
                        return <p><i className="fal fa-clock"></i>{moment(row.end, "Do MMM YY").format(Str.TABLE_DATE_FORMAT)} to {moment(row.start, "Do MMM YY").format(Str.TABLE_DATE_FORMAT)} {subjectDuration(row.start, row.end)}</p>
                    }
                    }else{
                      return <p><i className="fal fa-clock"></i>{moment(row.end, "Do MMM YY").format(Str.TABLE_DATE_FORMAT)} to {moment(row.start, "Do MMM YY").format(Str.TABLE_DATE_FORMAT)} {subjectDuration(row.start, row.end)}</p>
                    }                    
                })()
                :(
                  <p><i className="fal fa-clock"></i>{moment(row.end, "Do MMM YY").format(Str.TABLE_DATE_FORMAT)} to {moment(row.start, "Do MMM YY").format(Str.TABLE_DATE_FORMAT)} {subjectDuration(row.start, row.end)}</p>
                )}
                <ul className="num-info">
                  {row?.hide_learning_material_count == 0 ? <>
                  <li title="Videos"><i class="fal fa-video"></i><span>{total_videos ? total_videos : "0"}</span></li>
                  <li title="Quizzes"><i class="fal fa-clipboard-list"></i><span>{total_quizs ? total_quizs : "0"}</span></li>
                  <li title="Files"><i class="fal fa-file"></i><span>{total_documents ? total_documents : "0"}</span></li>
                  </> : null}
                  {row?.hide_assessments_count == 0 ? <li title="Assessments"><i className="fal fa-ballot-check"></i><span>{total_assessment ? total_assessment : "0"}</span></li> : null}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="lecture-detail-top-right d-flex">
          <ul className="lec-dtl-rgt-ul m-0 d-flex list-unstyled align-items-center flex-wrap">
            {/* <li>
              <div className="msg-icon">
                <i className="fal fa-comment-alt-lines"></i>
              </div>
            </li>
            <li>
              <div className="your-complete-date d-flex bg-white">
                <div className="yr-com-lft">
                  {row.type === "selfplaced" && row.subject_type === "shortcourse" ? <i className="fal fa-clock"></i> : <i className="fal fa-calendar-alt"></i>}
                </div>
                {/* <div className="yr-com-rgt">
                  <small>Your Completion Date</small>
                  <p className="m-0">
                    {showStart} To {showEnd} {subjectDuration(showStart,showEnd)}
                  </p>
                </div> 
                {props.isAdmin!=1 ? 
                  (() => {
                      if(row.type === "selfplaced" && row.subject_type === "shortcourse"){
                        switch (row.student_intake_status){
                          case 0:   
                          return <div className="yr-com-rgt">
                          <small>Course Duration</small>
                          <p className="m-0">{row?.subject_duration + " months"}</p>
                          </div>;
                          case 1: 
                          case 4: 
                          return <div className="yr-com-rgt">
                          <small>Course Duration</small>
                          <p className="m-0">{moment(row.showStart, "Do MMM YY").format(Str.TABLE_DATE_FORMAT)} to {moment(row.showEnd, "Do MMM YY").format(Str.TABLE_DATE_FORMAT)} {subjectDuration(row.showStart, row.showEnd)}</p>
                          </div>;
                          case 2:
                          return <div className="yr-com-rgt">
                          <small>Course Duration</small>
                          <p className="m-0">{moment(row.showTargetStart,"Do MMM YY").format(Str.TABLE_DATE_FORMAT)} to {moment(row.showCompletion,"Do MMM YY").format(Str.TABLE_DATE_FORMAT)} {subjectDuration(row.showTargetStart,row.showCompletion)}</p>
                          </div>;   
                          case 3:   
                          return <div className="yr-com-rgt">
                          <small>Your Completion Date</small>
                          <p className="m-0">{moment.unix(row.completed_date).format(Str.TABLE_DATE_FORMAT)} {subjectDuration(row.showTargetStart, row.showCompletion)}</p>
                          </div>;
                          default: 
                          return <div className="yr-com-rgt">
                          <small>Recommended Date</small>
                          <p className="m-0">{moment(row.showStart,"Do MMM YY").format(Str.TABLE_DATE_FORMAT)} to {moment(row.showEnd,"Do MMM YY").format(Str.TABLE_DATE_FORMAT)} {subjectDuration(row.showStart,row.showEnd)}</p>
                          </div>;
                        }

                      }else if(row.subject_type=="mentored"){
                        switch (row.student_intake_status) {
                          case 0:   
                          return <div className="yr-com-rgt">
                          <small>Recommended Date</small>
                          <p className="m-0">{moment(row.showStart,"Do MMM YY").format(Str.TABLE_DATE_FORMAT)} to {moment(row.showEnd,"Do MMM YY").format(Str.TABLE_DATE_FORMAT)} {subjectDuration(row.showStart,row.showEnd)}</p>
                          </div>;
                          case 1: 
                          case 4: 
                          return <div className="yr-com-rgt">
                          <small>Committed Date</small>
                          <p className="m-0">{moment(row.showTargetStart,"Do MMM YY").format(Str.TABLE_DATE_FORMAT)} to {moment(row.showTargetEnd,"Do MMM YY").format(Str.TABLE_DATE_FORMAT)} {subjectDuration(row.showTargetStart,row.showTargetEnd)}</p>
                          </div>;
                          case 2: 
                          case 3:   
                          return <div className="yr-com-rgt">
                          <small>Completion Date</small>
                          <p className="m-0">{moment(row.showTargetStart,"Do MMM YY").format(Str.TABLE_DATE_FORMAT)} to {moment(row.showCompletion,"Do MMM YY").format(Str.TABLE_DATE_FORMAT)} {subjectDuration(row.showTargetStart,row.showCompletion)}</p>
                          </div>;
                          default: 
                          return <div className="yr-com-rgt">
                          <small>Recommended Date</small>
                          <p className="m-0">{moment(row.showStart,"Do MMM YY").format(Str.TABLE_DATE_FORMAT)} to {moment(row.showEnd,"Do MMM YY").format(Str.TABLE_DATE_FORMAT)} {subjectDuration(row.showStart,row.showEnd)}</p>
                          </div>;
                      }
                      }else{
                       return <div className="yr-com-rgt">
                                <p className="m-0">{moment(row.showStart,"Do MMM YY").format(Str.TABLE_DATE_FORMAT)} to {moment(row.showEnd,"Do MMM YY").format(Str.TABLE_DATE_FORMAT)} {subjectDuration(row.showStart,row.showEnd)}</p>
                              </div>
                      }
                      
                  })()
                  :(<div className="yr-com-rgt">
                      <p className="m-0">{moment(row.showStart,"Do MMM YY").format(Str.TABLE_DATE_FORMAT)} to {moment(row.showEnd,"Do MMM YY").format(Str.TABLE_DATE_FORMAT)} {subjectDuration(row.showStart,row.showEnd)}</p>
                  </div>)}
              </div>
            </li> */}
            <li className="subject-status-li">
              <img src={statusIcon} alt="Status" />              
              <p className="m-0">{status}</p>
            </li>
            {/* {(props.isAdmin != 1 && (row?.subject_type=="shortcourse" || row.subject_type=="mentored")) ?             
              <MentoredSubject subjectContent={props.subjectContent} row={row} updateSubject={props.updateSubject} /> 
            : null} */}
          </ul>
        </div>
      </div>      
    </>
  );
};
export default SubjectHeadBox;