import React, { useState, useEffect, useRef, memo } from "react";
import {
  getAssembliesItem,
  getAssembliesItemAdmin,
  getAssembliesItemIDList,
  GetLearningMaterial,
  GetLearningMaterialAdmin
} from "../../services/AssembliesService";
import SekletonLearningMaterial from "../../loaders/SekletonLearningMaterial";
import SideAssemliesItems from "./SideAssemliesItems";
import { useDispatch, useSelector } from "react-redux";
import { UpdateLastLessonId, UpdateLessonType, clearActivityArr, clearAssessmentActive, clearCurrentActivity, clearCurrentCategory, clearLessonType, updateActivityArr, updateAssembliestItems, updateCurrentActivity, updateCurrentAssembly } from "../../store/actions";
import { TrimText } from "../common/TrimText";

const LeasonListLearningMaterial = (props) => {
  const { moduleId, setModuleId, assembliesItems, setassembliesItems } = props;
  const [assemblies, setAssemblies] = useState([]);
  const [assemblyIndex, setAssemblyIndex] = useState(null);
  const [loading, setLoading] = useState(true);
  const mounted = useRef(true);
  const dispatch = useDispatch();
  const currentAssembly = useSelector(state => state.learningMaterial.currentAssembly);
  const currentActivity = useSelector(state => state.learningMaterial.current_activity);
  const lesson_type = useSelector(state => state.learningMaterial.active_lesson_type);

  const getAssembliestItemsId = async (sid) => {
    
    try {
      const res = await getAssembliesItemIDList(sid);
      const data = res.data;
      const ActivityArr = [];

      const extractItems = (items) => {
        items.forEach((item) => {
          ActivityArr.push({ item_id: item.item_id, assembly_id: item.assembly_id, item_type: item.item_type });
          if (item.childrens) extractItems(item.childrens);
          if (item.sub_childrens) extractItems(item.sub_childrens);
        });
      };

      data.forEach((item) => {
        if (item.assembliesItemsIds) extractItems(item.assembliesItemsIds);
      });

      if (ActivityArr.length > 0) {
        dispatch(updateActivityArr(ActivityArr));
        dispatch(updateCurrentActivity(ActivityArr[0].item_id));
        dispatch(updateCurrentAssembly(ActivityArr[0].assembly_id));
        dispatch(clearLessonType());
      }
    } catch (error) {
      console.error("Error fetching assemblies items ID:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchLearningMaterial = async () => {
    
    try {
      const fetchFunction = props.isAdmin === 1 ? GetLearningMaterialAdmin : GetLearningMaterial;
      const res = await fetchFunction({ sid: props.match.params.sid });
      if (mounted.current && res.data?.assemblies) {
        const assembliesData = res.data.assemblies;
        const initialModuleId = assembliesData[0]?.lessons[0]?.module_id;
        setAssemblies(assembliesData);
        setModuleId(initialModuleId);
        props.setCurrentModuleId(initialModuleId);
      }
    } catch (error) {
      console.error("Error fetching learning material:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchAssembliesItem = async () => {
    
    if (!moduleId) return;
    try {
      const fetchFunction = props.isAdmin === 1 ? getAssembliesItemAdmin : getAssembliesItem;
      const res = await fetchFunction(moduleId);
      setassembliesItems(res.data);
      props.setNextModuleId(res.data[0]?.item_id);
      dispatch(updateCurrentActivity(moduleId));
      dispatch(clearLessonType());
    } catch (error) {
      console.error("Error fetching assemblies items:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    mounted.current = true;
    fetchLearningMaterial();
    getAssembliestItemsId(props.match.params.sid);

    return () => {
      mounted.current = false;
    };
  }, [props.isAdmin, props.match.params.sid]);

  useEffect(() => {
    fetchAssembliesItem();
  }, [moduleId, props.isAdmin]);

  useEffect(() => {
    if (currentAssembly) {
      let currentAssemblyIndex = assemblies.findIndex((assembly) => assembly.id === currentAssembly);
      setAssemblyIndex(currentAssemblyIndex);
    }
  }, [assemblies, currentAssembly]);

  const handleItem = (item, e) => {
    if (!e.currentTarget.className.includes("collapse")) {
      const fetchFunction = props.isAdmin === 1 ? getAssembliesItemAdmin : getAssembliesItem;
      fetchFunction(item)
        .then((res) => {
          setassembliesItems(res.data);
          setModuleId(item);
        })
        .catch((error) => console.error(error));
    }
  };

  const handleNextCategory = (item) => {
    dispatch(updateCurrentActivity(item.module_id));
    dispatch(updateCurrentAssembly(item.assembly_id));
    dispatch(UpdateLessonType(item.item_type));
  };

  useEffect(() => {
    if (currentActivity && lesson_type === 1) {
      let element = document.getElementById(`category${currentActivity}`);
      let allEle = document.querySelectorAll(".assembly-items");
      let allParent = document.querySelectorAll(".assembly-parent");
      let childEle = document.getElementById(`accordionBody${currentActivity}`);

      if (element && childEle) {
        allEle.forEach(ele => ele.classList.remove("show"));
        allParent.forEach(ele => ele.setAttribute("aria-expanded", false));
        element.setAttribute("aria-expanded", true);
        childEle.classList.add("show");
      }
    }
  }, [currentActivity, lesson_type]);

  return (
    <>
      {loading && <SekletonLearningMaterial />}
      {assemblies.length > 0 && !loading ? (
        assemblies.slice(assemblyIndex, assemblyIndex + 1).map((assembly) => (
          <div className="assembly-items-parent" id={"accord"+assembly?.id} key={assembly.id}>
            <div>
              <h3 className="assembly-name">{TrimText(assembly?.name || "NA", 25)}</h3>
              <p className="assembly-demmy-text">{TrimText(assembly?.item_description || "NA", 40)}</p>
            </div>

            <div className="assembly-list">
              {!props.match.path.includes("shortCourse") && (
                <div className="assembly-head">
                  <div className="assembly-head-top">
                    <p className="assembly-label">{TrimText(assembly?.name || "NA", 25)}</p>
                    <span className="assembly-top-icon"><i title="plus" className="fal fa-plus-circle" /></span>
                  </div>
                  <p className="assembly-desc">{TrimText(assembly.item_description, 40)}</p>
                </div>
              )}
              <div className="learning-material-listing">
                {assembly?.lessons?.map((lesson, lessonindex) => (
                  <div className="learning-material-card" key={lesson?.module_id}>
                    <div className="hd-main d-flex flex-wrap learning-box">
                      <div
                        className={`learning-material-head borderprimary assembly-parent`}
                        onClick={(e) => handleItem(lesson.module_id, e)}
                        data-toggle="collapse"
                        data-target={`#accordionBody${lesson.module_id}`}
                        data-parent={`#accord${lesson?.assembly_id}`}
                        aria-expanded="true"
                        aria-controls={`accordionBody${lesson.module_id}`}
                        id={`category${lesson.module_id}`}
                      >
                        <div className="learning-left">
                          <h3>
                            {assembly.isNumbered === 1 && lessonindex + 1}
                            {lesson.module_name}
                          </h3>
                          <div className="header-status">
                            <div className="status-gol">
                              <i className='fal fa-chevron-down'></i>
                            </div>
                          </div>
                        </div>
                        <div className="learning-right">
                          <ul className="learningright-inner d-flex align-items-center">
                            <li className="material-info deep-text">
                              <i title="Videos" className="fal fa-video" />
                              {lesson.assembliesVidoesFile}
                              <i title="Documents" className="fal fa-file" />
                              {lesson.assembliesDocumentFile}
                              <i title="Quiz" className="fal fa-clipboard-list" />
                              {lesson.assembliesQuiz}
                              <i title="Link" className="fal fa-external-link" />
                              {lesson.assembliesLinkFile}
                            </li>
                          </ul>
                        </div>
                        <div className="course-item-progress">
                          <div className="progress" style={{ height: "11px" }}>
                            <div
                              className="progress-bar progress-bar-text"
                              role="progressbar"
                              style={{ width: lesson.progressPercentag + "%" }}
                              aria-valuenow="25"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            >
                              {lesson.progressPercentag || "0"}%
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`learning-material-body assembly-items collapse ${lessonindex === 0 ? "show" : ""}`}
                        id={"accordionBody" + lesson.module_id}
                        aria-labelledby={"category" + lesson.module_id}
                        data-parent={"#accord" + lesson?.assembly_id}
                      >
                        <ul className="sub-ul">
                          <div
                            className={`assembly-material-head learning-material-body borderprimary ${lesson.module_id === currentActivity ? "lesson-active" : ""}`}
                            onClick={(e) => handleNextCategory(lesson)}
                          >
                            <div className="assembly-left d-flex">
                              <span className="assembly-input-span">
                                <input type="checkbox" className="assembly-check" readOnly />
                              </span>
                              <h3>
                                {assembly.isNumbered === 1 && lessonindex + 1}
                                {lesson.module_name}
                              </h3>
                            </div>
                            <div className="">
                              <div className="">
                                <i id="info-circle" className="fal fa-info-circle"></i>
                              </div>
                            </div>
                          </div>
                          {assembliesItems?.map((assemblyItem, index) => (
                            <SideAssemliesItems
                              assembliesItems={assembliesItems}
                              setActiveTab={props.setActiveTab}
                              subassembliesItems={props.subassembliesItems}
                              setSubassembliesItems={props.setSubassembliesItems}
                              setCurrentLesson={props.setCurrentLesson}
                              key={index}
                              isAdmin={props.isAdmin}
                              parentSubId={props.subjectId}
                              parentGetLearningMaterialData={handleItem}
                              assemblyItem={assemblyItem}
                              loading={loading}
                              lesson={lesson}
                              lessonindex={
                                assembly.isNumbered === 1
                                  ? parseInt(lessonindex + 1) + "." + parseInt(index + 1)
                                  : ""
                              }
                              match={props.match}
                            />
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))
      ) : !loading ? (
        <div
          className="learning-material-listing"
          id="accordion"
          role="tablist"
          aria-multiselectable="true"
        >
          <div className="subject-header">
            <div className="subject-sub-header">
              <h3>No Content Available.</h3>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default memo(LeasonListLearningMaterial);
