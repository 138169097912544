import React, { useState, useEffect, useRef, useCallback, memo } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { UpdateLessonType, updateCurrentActivity, updateCurrentAssembly } from "../../store/actions";

let obj = {};

function SideSubAssemliesItems(props) {
  const {
    assemblyItem,
    lesson,
  } = props;
  const currentActivity=useSelector(state=>state.learningMaterial.current_activity)

  const [video_duration_time, setVideo_duration_time]=useState("")
  const dispatch= useDispatch()

  const formatDuration = (duration) => {
    const parts = duration.match(/(\d+)([mhms])/g);
    let seconds = 0;
  
    parts.forEach(part => {
      const value = parseInt(part.match(/\d+/)[0], 10);
      const unit = part.match(/[mhms]/)[0];
  
      switch (unit) {
        case 'h':
          seconds += value * 3600;
          break;
        case 'm':
          seconds += value * 60;
          break;
        case 's':
          seconds += value;
          break;
        default:
          break;
      }
    });
  
    return moment.utc(seconds * 1000).format('mm:ss');
  };

  useEffect(() => {
    if(assemblyItem?.video_duration){
    const formattedTime = formatDuration(assemblyItem?.video_duration);
    setVideo_duration_time(formattedTime)}
  }, [assemblyItem?.video_duration])

  const handleOpenLesson = (lesson) => {
    dispatch(updateCurrentActivity(lesson.item_id))
    dispatch(updateCurrentAssembly(lesson.assembly_id))
    dispatch(UpdateLessonType(lesson.item_type))
  }

  return ( 
    <>
      <li key={lesson.item_id}>
        <div className={"sub-body" + (lesson.item_type === 2 ? ' video-sec' : '')} >
          {assemblyItem.item_type === 2 ? (
            <div className={`lesson-card ${assemblyItem.item_id == currentActivity ? "lesson-active" : ""}`}
            onClick={(e) => handleOpenLesson(assemblyItem)}> 
              <div className="card-media-text d-flex">
                <span className="assembly-input-span"><input type="checkbox" className="assembly-check"/></span>
                <div className="">
                  <p>{assemblyItem.item_name.includes(": ") ? assemblyItem.item_name.split(": ")[1] : assemblyItem.item_name}</p>
                  <p className="document-detail">{video_duration_time ? video_duration_time : "NA"}<span className="document-document-icon"><i className="fal fa-play"></i></span></p>
                </div>
              </div>
              <p className="assets">
              <i className="fal fa-video"></i>
              </p>
            </div>
          ) : null}


          {assemblyItem.item_type === 7 ? (
           <div className={`lesson-card ${assemblyItem.item_id == currentActivity ? "lesson-active" : ""}`}
            onClick={(e) => handleOpenLesson(assemblyItem)}>   
              <div className="card-media-text d-flex">
                <span className="assembly-input-span"><input type="checkbox" className="assembly-check"   /></span>
                <div className="">
                  <p>{assemblyItem.item_name.includes(": ") ? assemblyItem.item_name.split(": ")[1] : assemblyItem.item_name}</p>
                </div>
              </div>
              {/* <p className="assets">
                <i className="fal fa-ballot"></i>
              </p> */}
            </div>
          ) : null}

          {assemblyItem.item_type === 4 ? (
             <div className={`lesson-card ${assemblyItem.item_id == currentActivity ? "lesson-active" : ""}`}
             onClick={(e) => handleOpenLesson(assemblyItem)}>
              <div className="card-media-text d-flex" >
                <span className="assembly-input-span"><input type="checkbox" className="assembly-check" /></span>
                <div className="">
                  <p>{assemblyItem.item_name.includes(": ") ? assemblyItem.item_name.split(": ")[1] : assemblyItem.item_name}</p>
                  <p className="document-detail"><span className="document-size">{assemblyItem?.document_size ? `${assemblyItem?.document_size}+"MB"`  : "NA"}</span><span className="document-document-icon"><i className="fal fa-download"></i></span></p>
                </div>
              </div>
              <div className="assets">
                <i className="fal fa-file-pdf"></i>
              </div>
            </div>
          ) : null}

          {assemblyItem.item_type === 5 ? (
            <div className={`lesson-card ${assemblyItem.item_id == currentActivity ? "lesson-active" : ""}`}
            onClick={(e) => handleOpenLesson(assemblyItem)}> 
              <div className="card-media-text d-flex" >
                <span className="assembly-input-span"><input type="checkbox" className="assembly-check" /></span>
                <div className="">
                  <p>{assemblyItem.item_name.includes(": ") ? assemblyItem.item_name.split(": ")[1] : assemblyItem.item_name}</p>
                </div>
              </div>
              <div className="assets">
                <i className="fal fa-external-link"></i>
              </div>
            </div>
          ) : null}

          {assemblyItem.item_type === 6 ? (
             <div className={`lesson-card ${assemblyItem.item_id == currentActivity ? "lesson-active" : ""}`}
             onClick={(e) => handleOpenLesson(assemblyItem)}>   
              <div className="card-media-text d-flex" >
                <span className="assembly-input-span"><input type="checkbox" className="assembly-check" /></span>
                <div className="">
                  <p>{assemblyItem.item_name.includes(": ") ? assemblyItem.item_name.split(": ")[1] : assemblyItem.item_name}</p>
                </div>
              </div>
              <div className="assets">
              <i className="fal fa-external-link"></i>  
              </div>
            </div>
          ) : null}
        </div>
      </li>
    </>
  );
}

export default memo(SideSubAssemliesItems)