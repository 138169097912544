import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import QualificationShortInfo from "./QualificationShortInfo";
//import randomColor from "randomcolor";
import { download } from "../../utils/FileDownload";
import ShortNameBox from "./ShortNameBox";
import { useDispatch, useSelector } from "react-redux";
import { setIsExamLaunched } from "../../store/actions";
import { getSubjectStatus, subjectDuration } from "../../utils/commonFunction";
import CommonCourseComponents from "./CommonCourseComponents";
import moment from "moment";
import Str from "../common/Str";
import { math } from "@tensorflow/tfjs";
import LearningTabShortInfo from "./LearningTabShortInfo";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const downloadFile = async (absolutePath, fileName) => {
  await download(absolutePath, fileName);
};

const QualificationHeadBox = (props) => {
  const { row } = props;
  const {qid, sid}=useParams()
  const [color, setColor] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const { examLaunched } = useSelector(state => state.examLaunched);
  var icon = getSubjectStatus(row?.responseData?.subjectStatus);

  useEffect(() => {
    //setColor(randomColor());
  }, [props]);

  const [showProgress, setShowProgress] = useState(true);

  // Utility function to ensure the value is a number
  const toFixedOrDefault = (value, decimals = 2) => {
    const num = Number(value);
    return isNaN(num) ? `0` : num
  };
  return (
    <>
      <div className="sub-lft-head">
        <div className="all-courses">
          {history.location.pathname === examLaunched?.url && examLaunched?.isExamLaunched
            ? <Link
              onClick={() => dispatch(setIsExamLaunched({ ...examLaunched, isClicked: true }))}
            >
              <i className="fal fa-angle-left"></i>{!props.learningTab ? "Back" : "Back to Programme"}
            </Link>
            : <Link to={sid ? `/mycourses/${qid}` : "/mycourses"}>
              <i className="fal fa-angle-left"></i>{!props.learningTab ? "Back" : "Back to Programme"}
            </Link>
          }
        </div>

        <div className={props.learningTab ? "couses-bx-info-top-learning" : "couses-bx-info-top"}>

          {!props.learningTab &&
            <>
              <div className="couses-subject-head">
                <CommonCourseComponents degree={row?.responseData?.program_type ? row?.responseData?.program_type : "NA"} />
                <div className="d-flex align-items-center" >
                  <img src={icon} alt="" width="25px" height="25px" />
                  <p style={{ fontSize: "12px", marginLeft: "5px" }}>{row?.responseData?.subjectStatus}</p>
                </div>
              </div>

              <div className="couses-bx-info-right">
                <QualificationShortInfo qualification={row?.responseData} />
              </div>
            </>
          }

          {!props.learningTab && (
            <>
              <div className="d-flex flex-direction-column align-items-center">
                {false ? (
                  <p style={{ marginRight: "auto", marginTop: "5px", fontSize: "12px" }}>
                    <span className="couse_i_in">
                      <i className="fal fa-clock" style={{ marginRight: "5px" }}></i>
                    </span>
                    {row?.responseData?.courseDuration} months
                  </p>
                ) : (
                  <p style={{ marginRight: "auto", marginTop: "5px", fontSize: "12px" }}>
                    <span className="couse_i_in">
                      <i className="fal fa-calendar-alt" style={{ marginRight: "5px" }}></i>
                    </span>
                    {moment(row?.responseData?.end, "Do MMM YY").format(Str.TABLE_DATE_FORMAT)} to {moment(row?.responseData?.start, "Do MMM YY").format(Str.TABLE_DATE_FORMAT)} {subjectDuration(row.responseData?.end, row.responseData?.start)}
                  </p>
                )}
              </div>

              <div className="grade-overview">
                <p className="tittle">Programme Overview</p>

                <div className="overview">
                  <div className="overview-content">
                    <i className="fal fa-chart-bar"></i>
                    <span className="text">Final Grade</span>
                  </div>

                  <div className="grades">
                    <span className="grade-no">
                      {toFixedOrDefault(row?.responseData?.programCalculate?.programeAssMark, 2)}/{toFixedOrDefault(row?.responseData?.programCalculate?.programeAssTotalMark)}
                    </span>
                    <span className={row.responseData?.programCalculate?.pass_status !== "FAIL" ? "mark-status-pass" : "mark-status-fail"}>
                      {row.responseData?.programCalculate?.pass_status !== "FAIL" ? "Pass" : "Fail"}
                    </span>
                  </div>
                </div>
              </div>

              {showProgress && <div className="progress-program-qualification">
                <div className="programme">
                  <span className="task-name status"> {(toFixedOrDefault(row.responseData?.progress?.progressBar, 2))}% Programme Completed</span>

                  <div className="progress">
                    <div
                      className={"progress-bar"}
                      role="progressbar"
                      style={{
                        width: (toFixedOrDefault(row.responseData?.progress?.progressBar, 2)) + "%",
                      }}
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {toFixedOrDefault(row.responseData?.progress?.progressBar, 2)}%
                    </div>
                  </div>
                </div>

                <div className="programme">
                  <span className="task-name"> Learning Material</span>

                  <div className="progress">
                    <div
                      className={"progress-bar"}
                      role="progressbar"
                      style={{
                        width: (toFixedOrDefault(row.responseData?.progress?.totalLearningMaterialPercentage, 2)) + "%",
                      }}
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {toFixedOrDefault(row.responseData?.progress?.totalLearningMaterialPercentage, 2)}%
                    </div>
                  </div>
                </div>

                <div className="programme">
                  <span className="task-name"> Assessments</span>

                  <div className="progress">
                    <div
                      className={"progress-bar"}
                      role="progressbar"
                      style={{
                        width: (toFixedOrDefault(row.responseData?.progress?.totalAssessmentPercentage, 2)) + "%",
                      }}
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {toFixedOrDefault(row.responseData?.progress?.totalAssessmentPercentage, 2)}%
                    </div>
                  </div>
                </div>
              </div>
              }
              <button onClick={() => setShowProgress(!showProgress)} className="hide-Detail-progress">
                {showProgress ? (
                  <>
                    Hide Detailed Progress <i className="fal fa-angle-up" style={{ fontSize: "12px" }} />
                  </>
                ) : (
                  <>
                    Show Detailed Progress <i className="fal fa-angle-down" style={{ fontSize: "12px" }} />
                  </>
                )}
              </button>
            </>
          )}
        </div>

        {!props.learningTab && row?.responseData?.timeTable && row?.responseData?.timeTable !== "" && row?.responseData?.timeTable !== null ? (
          <button title="Download Timetable" onClick={() => downloadFile(row.file, row.fileName)} className="btn btn-white-bordered" style={{width:"100%"}}>
            <i className="fal fa-arrow-to-bottom"></i>Download Timetable
          </button>
        ) : null}
      </div>
    </>
  );
};

export default QualificationHeadBox;
